import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentSection from '../components/content-section'
import GridContentSection from '../components/grid-content-section'
import ServiceHero from '../components/service-hero'
import PredefinedComponentController from '../components/predefined-component-controller'
import TrophyBanner from '../components/trophy-banner'
import { parseContent } from '../helpers/city-pages'
import { CheckCircleIcon } from '@heroicons/react/solid'
import Breadcrumbs from '../components/Breadcrumbs'
import { BlogPostsOrRelated } from '../components/BlogPostsOrRelated'

export default function ServiceTemplate({ data, location }) {
  const { allContentfulServiceArea, relatedBlogs } = data
  const {
    title,
    slug,
    areasServiceService,
    metaTitle,
    metaDescription,
    pageContent,
    heroTitle,
    heroDescription,
    heroImage,
    contactFormHeading,
    heroSubtitle,
    trophyHeading,
    trophySubheading,
    heroDescriptionImage,
  } = data.contentfulService

  return (
    <Layout isServicePage={true} location={location}>
      <SEO title={metaTitle} description={metaDescription} />

      <div className="bg-white border-b border-gray-100">
        <nav
          className="flex container mx-auto px-4 py-3"
          aria-label="Breadcrumb"
        >
          <Breadcrumbs
            links={[
              { to: '/', label: 'Home' },
              { to: `/${slug}`, label: title },
            ]}
          />
        </nav>
      </div>

      {heroTitle != null && heroDescription != null ? (
        <ServiceHero
          slug={slug}
          contactFormHeading={contactFormHeading}
          heroImage={heroImage}
          heroDescriptionImage={heroDescriptionImage}
          title={heroTitle}
          subtitle={heroSubtitle}
          description={heroDescription.heroDescription}
        />
      ) : null}
      {trophyHeading != null || trophySubheading != null ? (
        <TrophyBanner heading={trophyHeading} subheading={trophySubheading} />
      ) : null}
      {pageContent.map((item, i) => {
        if (
          item.__typename === 'ContentfulContentSection' ||
          item.__typename === 'ContentfulContentWSidebar'
        ) {
          let showRightSidebar = item.__typename === 'ContentfulContentWSidebar'
          let sidebarArray = item && item.sidebar
          let renderCopyAsHtml = showRightSidebar

          //if show right sidebar, assuming left content section should render as html

          return (
            <ContentSection
              key={i}
              showRightSidebar={showRightSidebar}
              renderCopyAsHtml={renderCopyAsHtml}
              sidebarArray={sidebarArray}
              topHeading={item.topHeading}
              topSubheading={item.topSubheading}
              showStars={item.showStars}
              heading={item.heading}
              addImageContainer={item.addImageContainer}
              copy={item.copy.copy}
              image={item.image}
              layout={item.layout}
              buttonText={item.buttonText}
              buttonLink={item.buttonLink}
              buttonHelperText={item.buttonHelperText}
              cssClass={item.cssClass}
            />
          )
        }
        if (item.__typename === 'ContentfulPredefinedComponents') {
          return (
            <PredefinedComponentController
              key={i}
              moduleId={item.moduleId}
              componentID={item.contentful_id}
            />
          )
        }
        if (item.__typename === 'ContentfulGridContentSection') {
          return (
            <GridContentSection
              checkContentForCityTag={item.checkContentForCityTag}
              key={i}
              moduleId={item.moduleId}
              componentID={item.contentful_id}
              title={item.title}
              heading={item.heading}
              subheading={item.subheading}
              copy={item.copy && item.copy.copy}
              layout={item.layout}
              mobileItemsPerRow={item.mobileItemsPerRow}
              desktopItemsPerRow={item.desktopItemsPerRow}
              items={item.items}
            />
          )
        }
        return null
      })}

      {areasServiceService ? (
        <div className="bg-white shadow-sm">
          <div className="mx-auto container px-6 sm:px-6 py-12">
            <h3 className="mb-4 font-semibold text-xl md:text-2xl">
              Other Areas Served
            </h3>

            <ul className="flex flex-wrap list-none m-0">
              {allContentfulServiceArea.edges.map((item, i) => {
                return (
                  <li key={i} className="w-1/2 md:w-1/3 lg:w-1/4 flex my-2">
                    <div className="flex-shrink-0 pr-1">
                      <CheckCircleIcon className="h-5 w-5 text-green-500" />
                    </div>{' '}
                    <Link
                      className="text-gray-700 hover:text-brand-500 font-semibold font-display"
                      title={`${parseContent(
                        areasServiceService.serviceTitle,
                        item.node.location
                      )}`}
                      to={
                        '/' +
                        item.node.slug +
                        (item.node.stateAbbreviation
                          ? `-${item.node.stateAbbreviation.toLowerCase()}`
                          : '') +
                        ('-' + areasServiceService.slug)
                      }
                    >
                      {item.node.location}
                      {item.node.stateAbbreviation
                        ? `, ${item.node.stateAbbreviation}`
                        : null}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      ) : null}
      <BlogPostsOrRelated title={'From Our Blog'} data={relatedBlogs.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query serviceQuery($slug: String!, $categoryIds: [String]) {
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          id
          location
          slug
          state
          stateAbbreviation
        }
      }
    }
    contentfulService(slug: { eq: $slug }) {
      id
      slug
      title
      metaTitle
      areasServiceService {
        id
        serviceTitle
        slug
      }

      heroDescription {
        heroDescription
      }
      heroImage {
        fluid(maxWidth: 600) {
          srcSet
          srcWebp
          srcSetWebp
          ...GatsbyContentfulFluid
        }
      }
      heroDescriptionImage {
        fluid {
          srcSet
          srcWebp
          srcSetWebp
          ...GatsbyContentfulFluid
        }
        file {
          fileName
          url
          contentType
        }
      }
      title
      contactFormHeading
      heroTitle
      heroSubtitle
      trophyHeading
      trophySubheading
      metaDescription
      pageContent {
        __typename
        ... on ContentfulContentWSidebar {
          id
          copy {
            copy
          }
          sidebar {
            id
            content {
              content
            }
          }
        }
        ... on ContentfulContentSection {
          id
          title
          topHeading
          topSubheading
          showStars
          heading
          copy {
            copy
          }
          title
          cssClass
          buttonText
          buttonLink
          addImageContainer
          buttonHelperText
          image {
            id
            file {
              url
              contentType
            }
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          layout
          theme
          renderCopyAsHtml
        }
        ... on ContentfulPredefinedComponents {
          id
          title
          contentful_id
          moduleId
        }
        ... on ContentfulGridContentSection {
          checkContentForCityTag
          id
          title
          contentful_id
          title
          subheading
          heading
          copy {
            copy
          }
          layout
          mobileItemsPerRow
          desktopItemsPerRow
          items {
            __typename
            ... on ContentfulGridItem {
              id
              title
              copy {
                copy
              }
              layout
              svgIcon {
                svgIcon
              }
            }
            ... on ContentfulPricingPackage {
              title
              notes
              whatsIncluded {
                whatsIncluded
              }
              buttonLink
              buttonText
              startingAtPrice
              strikeOutPrice
              svgImage {
                svgImage
              }
              image {
                fluid(maxWidth: 350) {
                  srcSet
                  srcWebp
                  srcSetWebp
                  ...GatsbyContentfulFluid
                }
              }
              description {
                description
              }
              contentful_id
              id
            }
          }
        }
      }
    }
    relatedBlogs: allContentfulBlogPost(
      filter: { category: { elemMatch: { id: { in: $categoryIds } } } }
      limit: 8
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          heroImage {
            fluid(maxWidth: 400, quality: 70) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          metaDescription
          author {
            name
          }
          category {
            name
          }
        }
      }
    }
  }
`
